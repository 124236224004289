import * as React from "react"
import {navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import {isLoggedIn} from "../utils/auth.service";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import PromoImagesList from "../components/marketing/images-list";


const MarketingMaterials = () => {

    const [loading, setLoading] = useState(true);
    const [marketingImages, setMarketingImages] = useState(true);


  useEffect(() => {

    if(!isLoggedIn()){
     return navigate('/');
    }
      loadMarketingMaterials();

  }, [])


    const loadMarketingMaterials = () => {

        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        fetch(process.env.WASHCAR_API_URL + "/partners/marketing-materials", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
               setMarketingImages(resultData.partner_promo_images);
                setLoading(false);
            }) //
    }



    const download = (e, link) => {

        fetch(link, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "promoImage.png"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };


    return (
    <Layout>

      <Seo title="Mārketinga materiāli" />
      <h1 className="title">Mārketinga materiāli</h1>
        <div className={'notification is-warning'}>Šeit ir piejami dažādi digitālie mārketinga materiāli. Variet ar šiem materiāliem dalīties sociālajos tīklos un citur.</div>

        {loading ? <div className="pos-center"><LoadingSpinner /></div> : <PromoImagesList download={download} marketingImages={marketingImages} />}

    </Layout>
  )
}

export default MarketingMaterials
