import React from "react";

const PromoImagesList = (props) => {

    return (

        props.marketingImages.length === 0 ?
            <div className={'notification'}>Šeit būs redzami mārketinga meteriāli.</div> :

            <div className={'box'}>
                <div className="list">
                    {props.marketingImages.map(image => (
                        <div key={image.id} className="list-item">
                            <div className="list-item-content">
                                <div className="list-item-title">
                                    <img alt={'promo image'} src={image.link}/>
                                </div>

                                <div className="list-item-description">
                                    <button        onClick={e => props.download(e, image.link)}
                                         className={'button is-info mb-4'}>Lejupielādēt</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default PromoImagesList;

